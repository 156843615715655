import React, { memo, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import cn from 'classnames';

// Material UI
import { Fade, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// Actions

// My Components
import Header from '../Header/Header';
import Notification from '../Notification/Notification';
import favicon from '../../assets/favicon.ico';
import { selectCurrentUser, selectLoadingCurrentUser } from '../../store/app/selectors';
import { selectLayoutState } from '../../store/layout/selectors';
import Loading from '../Loading';
import Footer from '../Footer/Footer';
import BigLoaderWrap from '../Loading/Loader/BigLoaderWrap';
import VerifyCode from '../Forms/VerifyCode';
import LoaderBackdrop from '../Loading/Loader/LoaderBackdrop';
import useVerifiedQuery from '../../hooks/useVerifiedQuery';
import useEntryQuery from '../../hooks/useEntryQuery';
import { getAuthStatus, isClientFirstCheck } from '../../hooks/useAuth';
import { POST_MESSAGE_TYPE } from '../../utils/helpers/network-service';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    backgroundColor: theme.palette.background.body,
    paddingBottom: '0.1px',
  },
  wrapper: {
    height: '100%',
    backgroundColor: theme.palette.background.body,
  },
  wrapperMarginBottom: {
    marginBottom: theme.spacing(10),
  },
  wrapperIframe: {
    backgroundColor: theme.palette.iframe.body,
  },
  wrapperIframeOverflow: {
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
}));

const WithSubheaderLayout = ({ children }) => {
  const classes = useStyles();
  const [loadedLayout, setLoadedLayout] = useState(false);
  const { pathname, query } = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isIframeMode = query?.is_iframe === 'true';
  const loading = useSelector(selectLoadingCurrentUser);
  const user = useSelector(selectCurrentUser);
  const layoutState = useSelector(selectLayoutState);
  const { parseQuery } = useEntryQuery();
  const { isAccessTokenValid, isRefreshTokenValid } = getAuthStatus();

  /**
   * Keep the full screen loading when processing the redirection to client flow
   * by checking
   * IF user not logged in (via tokens)
   * AND "route" field in entry query that SSO route user access is NOT empty
   * In case route is empty, user will be redirect back to the SSO client which create request
   * (handled at useAuth hook)
   */
  useEffect(() => {
    const isNotLoggedIn = !isAccessTokenValid && !isRefreshTokenValid;
    if (
      (isNotLoggedIn && !isClientFirstCheck(parseQuery.route, parseQuery.client_id)) ||
      (isAccessTokenValid && pathname !== '/login')
    ) {
      setLoadedLayout(true);
    }
  }, []);

  useEffect(() => {
    const rejectUnverified =
      query.reject_unverified &&
      JSON.parse(query.reject_unverified) &&
      user.email_verification_status === 'unverified';
    if (!loadedLayout && rejectUnverified) {
      setLoadedLayout(true);
    }
  }, [user, loadedLayout]);

  /**
   * Check is the required-entry-query URL includes the entry query or not.
   * If not, client send the request to SSO server to get the entry query
   */
  useVerifiedQuery();

  const divRef = useRef(null);

  useEffect(() => {
    const divElement = divRef.current;
    if (divElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (entries[0].contentRect.height) {
          window.parent.postMessage(
            { type: POST_MESSAGE_TYPE.RESIZE, height: entries[0].contentRect.height },
            '*',
          );
        }
      });
      resizeObserver.observe(divElement);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [loadedLayout]);

  return !loadedLayout ? (
    <BigLoaderWrap haveLoading={false} />
  ) : (
    <>
      <div
        className={cn(classes.root, {
          [classes.wrapperIframe]: isIframeMode,
        })}
      >
        <Helmet
          titleTemplate="%s | Sankaku Complex"
          defaultTitle="Sankaku Complex"
          link={[{ rel: 'icon', type: 'image/png', href: favicon }]}
        />

        {!isIframeMode && <Header />}
        {layoutState.loading && <LoaderBackdrop />}
        <Notification />
        <VerifyCode />
        {/* Page content */}
        <Fade
          in
          className={cn(classes.wrapper, {
            [classes.wrapperMarginBottom]: !isIframeMode,
            [classes.wrapperIframe]: isIframeMode,
            [classes.wrapperIframeOverflow]: isIframeMode && !isMobile,
          })}
        >
          <div ref={divRef}>
            {loading && <Loading pastDelay />}
            {!loading && children}
          </div>
        </Fade>
      </div>
      {!isIframeMode && <Footer />}
    </>
  );
};

WithSubheaderLayout.whyDidYouRender = true;

export default memo(WithSubheaderLayout);
